import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/antd.less';

document.addEventListener('DOMContentLoaded', () => {
  import('./Application')
    .then((application) => {
      ReactDOM.render(
        <application.Application />,
        document.getElementById('root'),
      );
    });
});
